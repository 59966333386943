import { Box, Text, useColorModeValue } from "@chakra-ui/react";
import { useIntl } from "react-intl";

/**
 * @typedef {object} Props
 * @property {string} label
 * @property {number} [rate]
 */

/**
 * @param {Props} props
 */
export default function BookingReviewRate({ label, rate }) {
  const labelColor = useColorModeValue("gray.500", "gray.200");

  const rateColor = useColorModeValue("black", "gray.200");

  const intl = useIntl();

  return (
    <Box textAlign="center">
      <Text fontSize=".75rem" color={labelColor}>
        {label}
      </Text>

      <Text color={rateColor}>
        {rate
          ? intl.formatMessage({ defaultMessage: "{rate}/10" }, { rate })
          : "-"}
      </Text>
    </Box>
  );
}
