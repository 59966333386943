import { Box, Text } from "@chakra-ui/react";
import { memo, useMemo } from "react";

const BookingReviewAverageNote = memo(
  /**
   * @typedef {object} Props
   * @property {number} averageRate
   * @property {string} [width]
   * @property {string} [fontSize]
   */

  /**
   * @param {Props} props
   */
  function BookingReviewAverageNote({
    averageRate,
    width = "32px",
    fontSize = "1rem",
  }) {
    const getBackgroundColor = useMemo(() => {
      if (!averageRate || (averageRate > 0 && averageRate <= 2)) {
        return "gray.300";
      } else if (averageRate > 2 && averageRate <= 5) {
        return "gray.500";
      } else if (averageRate > 5 && averageRate <= 7) {
        return "brandPrimary.500";
      } else if (averageRate > 7 && averageRate <= 9) {
        return "brandPrimary.600";
      } else if (averageRate > 9 && averageRate <= 10) {
        return "brandPrimary.700";
      }
    }, [averageRate]);

    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        width={width}
        height={width}
        backgroundColor={getBackgroundColor}
        borderRadius="10px">
        <Text fontSize={fontSize} color="white">
          {averageRate}
        </Text>
      </Box>
    );
  },
);

export default BookingReviewAverageNote;
