import {
  CARTS_BOOKING_REVIEW_OWNER_STATE_VALUE_REJECTED,
  CARTS_BOOKING_REVIEW_STATE_VALUE_PENDING,
} from "../../../../constants/cartBookingReview";

/**
 * Retourne vrai si l'avis est en attente de moderation.
 * @param {object} param0
 * @param {import("../../../../types/CartBookingReview").CartBookingReview} param0.bookingReview
 * @returns {boolean}
 */
export default function bookingReviewIsStateWaitingForModeration({
  bookingReview,
}) {
  return (
    bookingReview.state === CARTS_BOOKING_REVIEW_STATE_VALUE_PENDING &&
    bookingReview.owner_state ===
      CARTS_BOOKING_REVIEW_OWNER_STATE_VALUE_REJECTED
  );
}
