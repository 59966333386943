import {
  cartsBookingReviewStatesWithVirtualStatesMessage,
  CARTS_BOOKING_REVIEW_OWNER_STATE_VALUE_REJECTED,
  CARTS_BOOKING_REVIEW_STATES_WITH_VIRTUAL_STATES,
  CARTS_BOOKING_REVIEW_STATE_VALUE_MODERATED,
  CARTS_BOOKING_REVIEW_STATE_VALUE_PENDING,
  CARTS_BOOKING_REVIEW_STATE_VALUE_PUBLISHED,
} from "../../../../constants/cartBookingReview";
import bookingReviewIsStateModerated from "../isBookingReviewModerated";
import bookingReviewIsStatePending from "../isBookingReviewPending";
import bookingReviewIsStatePublished from "../isBookingReviewPublished";
import bookingReviewIsStateWaitingForModeration from "../isBookingReviewWaitingForModeration";

/**
 * @typedef {object} BookingReviewVirtualState
 * @property {keyof typeof import("../../../../constants/cartBookingReview").CARTS_BOOKING_REVIEW_STATES_WITH_VIRTUAL_STATES | "unknown"} state
 * @property {import("react-intl").MessageDescriptor} message
 * @property {object} states
 */

/**
 * @param {object} params
 * @param {import("../../../../types/CartBookingReview").CartBookingReview} params.bookingReview
 * @returns {BookingReviewVirtualState}
 */
export function getVirtualState({ bookingReview }) {
  if (bookingReviewIsStateWaitingForModeration({ bookingReview })) {
    return {
      state: CARTS_BOOKING_REVIEW_OWNER_STATE_VALUE_REJECTED,
      message: cartsBookingReviewStatesWithVirtualStatesMessage,
      states: CARTS_BOOKING_REVIEW_STATES_WITH_VIRTUAL_STATES,
    };
  }

  if (bookingReviewIsStatePending({ bookingReview })) {
    return {
      state: CARTS_BOOKING_REVIEW_STATE_VALUE_PENDING,
      message: cartsBookingReviewStatesWithVirtualStatesMessage,
      states: CARTS_BOOKING_REVIEW_STATES_WITH_VIRTUAL_STATES,
    };
  }

  if (bookingReviewIsStateModerated({ bookingReview })) {
    return {
      state: CARTS_BOOKING_REVIEW_STATE_VALUE_MODERATED,
      message: cartsBookingReviewStatesWithVirtualStatesMessage,
      states: CARTS_BOOKING_REVIEW_STATES_WITH_VIRTUAL_STATES,
    };
  }

  if (bookingReviewIsStatePublished({ bookingReview })) {
    return {
      state: CARTS_BOOKING_REVIEW_STATE_VALUE_PUBLISHED,
      message: cartsBookingReviewStatesWithVirtualStatesMessage,
      states: CARTS_BOOKING_REVIEW_STATES_WITH_VIRTUAL_STATES,
    };
  }

  return {
    state: "unknown",
    message: cartsBookingReviewStatesWithVirtualStatesMessage,
    states: CARTS_BOOKING_REVIEW_STATES_WITH_VIRTUAL_STATES,
  };
}
