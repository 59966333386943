import { CARTS_BOOKING_REVIEW_STATE_VALUE_PUBLISHED } from "../../../../constants/cartBookingReview";

/**
 * Retourne vrai si l'avis est publié.
 * @param {object} param0
 * @param {import("../../../../types/CartBookingReview").CartBookingReview} param0.bookingReview
 * @returns {boolean}
 */
export default function bookingReviewIsStatePublished({ bookingReview }) {
  return bookingReview.state === CARTS_BOOKING_REVIEW_STATE_VALUE_PUBLISHED;
}
