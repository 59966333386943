import { CARTS_BOOKING_REVIEW_STATE_VALUE_PENDING } from "../../../../constants/cartBookingReview";

/**
 * Retourne vrai si l'avis est en attente.
 * @param {object} param0
 * @param {import("../../../../types/CartBookingReview").CartBookingReview} param0.bookingReview
 * @returns {boolean}
 */
export default function bookingReviewIsStatePending({ bookingReview }) {
  return bookingReview.state === CARTS_BOOKING_REVIEW_STATE_VALUE_PENDING;
}
