import {
  Avatar,
  Box,
  Flex,
  HStack,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { memo } from "react";
import { useIntl } from "react-intl";
import { SHORT_DATE_OPTIONS } from "../../../../constants/intl";
import {
  bookingsGetNumberOfNights,
  bookingsGetNumberOfPersons,
} from "../../../../helpers/bookings";
import { getVirtualState } from "../../../../helpers/bookings/reviews/getVirtualState";
import ResourceState from "../../../Resource/State";
import BookingReviewAverageNote from "../AverageRate";
import BookingReviewRate from "../Rate";

const BookingReviewDetail = memo(
  /**
   * @typedef {object} Props
   * @property {import("../../../../types/CartBookingReview").CartBookingReview} bookingReview
   * @property {boolean} [withUser]
   * @property {boolean} [withState]
   * @property {boolean} [withOwnerResponse]
   */

  /**
   * @param {Props} props
   */
  function BookingReviewDetail({
    bookingReview,
    withUser = true,
    withState = true,
    withOwnerResponse = false,
  }) {
    const intl = useIntl();

    const virtualState = getVirtualState({ bookingReview });

    const backgroundColor = useColorModeValue("gray.50", "gray.700");

    const textColor = useColorModeValue("black", "white");

    return (
      <Stack
        spacing=".75rem"
        backgroundColor={backgroundColor}
        p=".75rem"
        borderRadius="6px">
        {((bookingReview.public_name && withUser) || withState) && (
          <HStack justifyContent="space-between">
            {bookingReview.public_name && withUser && (
              <Flex gap=".5rem" mb=".25rem">
                <Avatar size="xs" name={bookingReview.public_name} />

                <Text fontWeight={500}>{bookingReview.public_name}</Text>
              </Flex>
            )}
            {withState && (
              <ResourceState
                state={virtualState.state}
                states={virtualState.states}
                message={virtualState.message}
              />
            )}
          </HStack>
        )}
        <Flex gap="1rem">
          <Flex alignItems="center">
            <BookingReviewAverageNote
              averageRate={bookingReview.average_rate}
            />
          </Flex>
          <Box>
            {bookingReview.checkin && bookingReview.checkout && (
              <Text color={textColor}>
                {bookingReview.adults || bookingReview.children
                  ? intl.formatMessage(
                      {
                        defaultMessage: `Séjour de {numberOfNights, plural,
                one {1 nuit}
                other {# nuits}
              } du {date} pour {numberOfPersons, plural,
                one {1 personne}
                other {# personnes}
              }`,
                      },
                      {
                        date: intl.formatDate(
                          bookingReview.checkin,
                          SHORT_DATE_OPTIONS,
                        ),
                        numberOfNights: bookingsGetNumberOfNights({
                          booking: {
                            checkin: bookingReview.checkin,
                            checkout: bookingReview.checkout,
                          },
                        }),
                        numberOfPersons: bookingsGetNumberOfPersons({
                          booking: {
                            adults: bookingReview.adults ?? 0,
                            children: bookingReview.children ?? 0,
                          },
                        }),
                      },
                    )
                  : intl.formatMessage(
                      {
                        defaultMessage: `Séjour de {numberOfNights, plural,
                one {1 nuit}
                other {# nuits}
              } du {date}`,
                      },
                      {
                        date: intl.formatDate(
                          bookingReview.checkin,
                          SHORT_DATE_OPTIONS,
                        ),
                        numberOfNights: bookingsGetNumberOfNights({
                          booking: {
                            checkin: bookingReview.checkin,
                            checkout: bookingReview.checkout,
                          },
                        }),
                      },
                    )}
              </Text>
            )}
            <Text variant="text-xs" color="gray.500">
              {intl.formatMessage(
                {
                  defaultMessage: "Avis déposé le {date}",
                },
                {
                  date: intl.formatDate(
                    bookingReview.created_at,
                    SHORT_DATE_OPTIONS,
                  ),
                },
              )}
            </Text>
          </Box>
        </Flex>

        <HStack
          justifyContent="space-between"
          maxWidth={{ base: "full", md: "65%" }}>
          <BookingReviewRate
            label={intl.formatMessage({ defaultMessage: "Accueil" })}
            rate={bookingReview.greeting_rate}
          />
          <BookingReviewRate
            label={intl.formatMessage({ defaultMessage: "Propreté" })}
            rate={bookingReview.fairness_rate}
          />

          <BookingReviewRate
            label={intl.formatMessage({ defaultMessage: "Confort" })}
            rate={bookingReview.cosiness_rate}
          />

          <BookingReviewRate
            label={intl.formatMessage({ defaultMessage: "Lieu" })}
            rate={bookingReview.location_rate}
          />

          <BookingReviewRate
            label={intl.formatMessage({ defaultMessage: "Qualité/Prix" })}
            rate={bookingReview.quality_rate}
          />
        </HStack>
        <Stack
          spacing=".5rem"
          borderBottom="1px"
          borderColor="gray.300"
          pb=".5rem">
          {bookingReview.pros && (
            <Flex display="inline-flex" gap=".75rem">
              <Text fontSize=".875rem" color="brandPrimary.500" minWidth="3rem">
                {intl.formatMessage({ defaultMessage: "Les +" })}
              </Text>
              <Text fontSize=".875rem" title={bookingReview.pros} noOfLines={5}>
                {bookingReview.pros}
              </Text>
            </Flex>
          )}

          {bookingReview.cons && (
            <Flex display="inline-flex" gap=".75rem">
              <Text fontSize=".875rem" color="brandPrimary.500" minWidth="3rem">
                {intl.formatMessage({ defaultMessage: "Les -" })}
              </Text>
              <Text fontSize=".875rem" title={bookingReview.cons} noOfLines={5}>
                {bookingReview.cons}
              </Text>
            </Flex>
          )}
        </Stack>

        {bookingReview.summary && (
          <Text fontSize=".875rem" whiteSpace="pre-line">
            {intl.formatMessage(
              { defaultMessage: "<blue>Commentaire</blue> {summary}" },
              {
                blue: (chunks) => (
                  <Text color="brandPrimary.500" as="span" mr=".25rem">
                    {chunks}
                  </Text>
                ),
                summary: bookingReview.summary,
              },
            )}
          </Text>
        )}

        {bookingReview.owner_response && withOwnerResponse && (
          <Text fontSize=".875rem" whiteSpace="pre-line">
            {intl.formatMessage(
              {
                defaultMessage:
                  "<blue>Réponse du propriétaire </blue> {summary}",
              },
              {
                blue: (chunks) => (
                  <Text color="brandPrimary.500" as="span" mr=".25rem">
                    {chunks}
                  </Text>
                ),
                summary: bookingReview.owner_response,
              },
            )}
          </Text>
        )}
      </Stack>
    );
  },
);

export default BookingReviewDetail;
