import { CARTS_BOOKING_REVIEW_STATE_VALUE_MODERATED } from "../../../../constants/cartBookingReview";

/**
 * Retourne vrai si l'avis est modéré.
 * @param {object} param0
 * @param {import("../../../../types/CartBookingReview").CartBookingReview} param0.bookingReview
 * @returns {boolean}
 */
export default function bookingReviewIsStateModerated({ bookingReview }) {
  return bookingReview.state === CARTS_BOOKING_REVIEW_STATE_VALUE_MODERATED;
}
